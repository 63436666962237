import React from "react";
import "./Section8.css";

const Section8 = () => {
  return (
    <div className="container8">
      <div className="leftt">
        <h1 className="brooke">Brooke R.</h1>
        <h1 className="power">Powered by Zyro.com</h1>
      </div>

      <div className="middlee">― SOCIALS Linkedin Facebook Twitter</div>
      <div className="rightt">― CONTACTS your@mail.com 123-123-1234</div>
    </div>
  );
};

export default Section8;
